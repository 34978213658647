'use strict';

import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import moment from 'moment-timezone';
import Chart from 'react-apexcharts';

const ResponseTimeChart = ({
  data,
  responseTimeSLA,
  min,
  max,
  isLarge = false,
  width,
  height = 55,
  tooltipLabel = 'Response Time',
}) => {
  const colors = {
    success: '124, 180, 236',
    warning: '245, 180, 73',
    danger: '229, 114, 110',
  };
  let selectedColor = 'success';
  if (data.length > 0 && responseTimeSLA !== null) {
    selectedColor = Formatter.responseTimeCSSClass(data[data.length - 1][1], responseTimeSLA);
  }
  const series = [
    {
      name: tooltipLabel,
      data: data.map((x) => ({
        x: moment.utc(x[0]).unix(),
        y: parseFloat((x[1] * 1000.0).toFixed(2)),
      })),
    },
  ];
  const options = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: !isLarge,
      },
      animations: {
        enabled: false,
      },
    },
    fill: {
      colors: ['rgba(' + colors[selectedColor] + ', 0.25)'],
      type: 'solid',
    },
    colors: ['rgba(' + colors[selectedColor] + ', 0.75)'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: isLarge,
        formatter: (v) =>
          moment.unix(v).tz(window.TIMEZONE).format(Formatter.getCurrentDateTimeFormattedString('MMM D, h:mm z')),
      },
      axisTicks: {
        show: isLarge,
      },
      axisBorder: {
        show: isLarge,
      },
    },
    yaxis: {
      labels: {
        show: isLarge,
        formatter: (v) => Formatter.autoDuration(v / 1000),
      },
      axisTicks: {
        show: isLarge,
      },
      axisBorder: {
        show: isLarge,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: true,
    },
    tooltip: {
      y: {
        formatter: (v) => Formatter.autoDuration(v / 1000),
      },
      x: {
        formatter: (v) =>
          moment
            .unix(v)
            .tz(window.TIMEZONE)
            .format(Formatter.getCurrentDateTimeFormattedString('MMM D, YYYY h:mm:ssa z')),
      },
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetX: -140,
        offsetY: -40,
      },
    },
  };

  if (min) {
    options.xaxis.min = moment.utc(min).unix();
  }
  if (max) {
    options.xaxis.max = moment.utc(max).unix();
  }

  return (
    <Chart
      type="area"
      series={series}
      options={options}
      width={width ? width : '100%'}
      height={height ? height : '100%'}
    />
  );
};

ResponseTimeChart.defaultProps = {
  min: null,
  max: null,
  data: null, // 2-tuples of (timestamp, response_time)
  width: null, // Render a fixed-width chart, specify width in pixels
  height: 55, // Height of the chart, 36, 45, 55, 75 or 225
  responseTimeSLA: null, // Color code the chart based on response time vs SLA
  tooltipLabel: 'Response Time',
};

export default ResponseTimeChart;
